import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameInitials',
  standalone: true,
})
export class NameInitialsPipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    if (!value) return '';
    const splitedName = value.toUpperCase().split(' ');
    if (splitedName.length > 1) {
      return splitedName[0][0] + splitedName[1][0];
    }
    return value;
  }
}
