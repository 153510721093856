import { Injectable, OnDestroy } from '@angular/core';
import {
  Auth,
  confirmPasswordReset,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
} from '@angular/fire/auth';
import { Firestore, getDoc } from '@angular/fire/firestore';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { browserLocalPersistence, signOut, User } from '@firebase/auth';
import { doc } from '@firebase/firestore';
import { isEqual } from 'lodash';
import {
  BehaviorSubject,
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  from,
  interval,
  map,
  Observable,
  Observer,
  of,
  Subject,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { IsBusyService } from './is-busy.service';
import { NotificationsService } from './notifications.service';
import { Store } from '@ngrx/store';
import { Model } from 'dashboard-frontend-library/models';
import { AppState } from '../store/app.state';
import { AuthenticationActions } from '../store/types';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private errorMessageSubject = new BehaviorSubject<string>('');
  errorMessage$ = this.errorMessageSubject.asObservable();
  private readonly onAuthenticated!: (
    data: Model.AuthenticatedRequest
  ) => Observable<void>;

  private currentFirebaseUser$: Subject<User | null> =
    new Subject<User | null>();
  private currentUserId$!: Observable<string | undefined>;

  currentAuthenticatedUser$!: Observable<Model.User | null>;
  constructor(
    private firestore: Firestore,
    private store: Store<AppState>,
    private fireAuth: Auth,
    private fireFunctions: Functions,
    private isBusyService: IsBusyService,
    private notificationService: NotificationsService
  ) {
    this.onAuthenticated = httpsCallableData(
      this.fireFunctions,
      'onAuthenticated'
    );

    const tokenChangeObserver: Observer<User | null> = {
      next: (firebaseUser) => {
        if (firebaseUser == null || firebaseUser.emailVerified === false) {
          this.currentFirebaseUser$.next(null);
        } else {
          this.currentFirebaseUser$.next(firebaseUser);
        }
      },
      error: (err) => console.error(err),
      complete: () => console.debug('completed'),
    };
    this.fireAuth.onIdTokenChanged(tokenChangeObserver);

    this.currentUserId$ = this.currentFirebaseUser$.pipe(
      map((user) => user?.uid),
      distinctUntilChanged()
    );

    this.currentAuthenticatedUser$ = this.currentUserId$.pipe(
      switchMap((id) =>
        id
          ? from(getDoc(doc(this.firestore, 'users', id))).pipe(
              map((doc) => doc.data() as Partial<Model.User>)
            )
          : of(null)
      ),
      map((user) => (user ? ({ ...user, token: '' } as Model.User) : null))
    );

    this.fireAuth.setPersistence(browserLocalPersistence);
    this.startStorageEventListener();
  }

  async init(): Promise<Model.User | null> {
    return await firstValueFrom(this.currentAuthenticatedUser$);
  }

  login(loginReq: Model.LoginRequest) {
    return from(
      signInWithEmailAndPassword(
        this.fireAuth,
        loginReq.email,
        loginReq.password
      )
    ).pipe(
      catchError((_) => {
        this.errorMessageSubject.next('Incorrect Username or Password');
        throw new Error('Incorrect Username or Password');
      }),
      map((cred) => {
        if (cred?.user.emailVerified === false) {
          this.errorMessageSubject.next('Email was not verified');
          throw new Error('Email was not verified');
        } else {
          return null;
        }
      }),
      catchError((err) => {
        return throwError(() => new Error(err.message));
      })
    );
  }

  resetPassword(
    resetPasswordReq: Model.ResetPasswordRequest
  ): Promise<boolean> {
    return this.isBusyService.add(async () => {
      try {
        await sendPasswordResetEmail(this.fireAuth, resetPasswordReq.email);
        return true;
      } catch {
        this.errorMessageSubject.next('Incorrect Username');
        return false;
      }
    });
  }

  verifyResetPasswordCode(code: string): Promise<string> {
    return this.isBusyService.add(async () => {
      try {
        return await verifyPasswordResetCode(this.fireAuth, code);
      } catch {
        this.notificationService.error(
          'Invalid or expired code, please try reseting password again'
        );
        return '';
      }
    });
  }

  confirmNewPassword(newPasswordReq: Model.NewPasswordRequest): Promise<void> {
    return this.isBusyService.add(
      () =>
        confirmPasswordReset(
          this.fireAuth,
          newPasswordReq.code,
          newPasswordReq.password
        ),
      undefined,
      'Invalid or expired code, please try reseting password again'
    );
  }

  async setClaimsForRegularUsers(
    onAuthReq: Model.AuthenticatedRequest,
    authUser: Model.User
  ): Promise<string> {
    return await this.isBusyService.add(async () => {
      try {
        await firstValueFrom(this.onAuthenticated(onAuthReq));
        const claims = {
          fleetId: authUser.fleetId,
          shipId: authUser.shipId,
          subFleetShipsId: authUser.subFleetShipsId,
        };
        const token = await this.refreshToken(claims);
        return token;
      } catch {
        this.notificationService.error('Invalid permissions');
        return null;
      }
    });
  }

  async logout(): Promise<void> {
    await signOut(this.fireAuth);
  }

  logoutFromAllOpenTab(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      window.localStorage.setItem('logout-event', Math.random().toString());
      resolve();
    });
  }

  async refreshToken(claims: {
    [key: string]: string | number | number[] | null;
  }): Promise<string> {
    this.fireAuth.currentUser?.refreshToken;
    const TOKEN_REFRESH_INTEVAL_MS = 1000;
    // the new token isnt auto contains the new claims, one should refresh it, problem is, firebase has its own mech to incoporate the claims into the token and only guarantees
    // the new claims will be after the user re logs, so we need some kind of mech to wait untill they incoporate the new claims into the token before sending any new requests.

    // our mech is take the first value of -
    // every 1 sec try to get the new token, filter out any token that doesnt include all claims that were requested, e.g
    // we requested fleetId: 10 and shipId: 42, all tokens with other data doesnt count, untill we get the correct token
    // success
    return await firstValueFrom(
      interval(TOKEN_REFRESH_INTEVAL_MS).pipe(
        concatMap((_) =>
          from(this.fireAuth.currentUser!.getIdTokenResult(true))
        ),
        filter((tokenRes) => {
          const allClaimsEqual = Object.keys(claims)
            .map((claim) => isEqual(tokenRes.claims[claim], claims[claim]))
            .every((wasClaimFound) => wasClaimFound === true);
          return allClaimsEqual;
        }),
        map((tokenRes) => tokenRes.token)
      )
    );
  }

  private startStorageEventListener(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      if (event?.key && event.key == 'logout-event') {
        this.store.dispatch(AuthenticationActions.logoutFromAllTab());
      }
    }
  }

  private stopStorageEventListener(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
  }

  ngOnDestroy() {
    this.stopStorageEventListener();
  }
}
