import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthenticationState } from './authentication.state';
import { Role } from 'dashboard-frontend-library/models/auth/user.model';

export const selectAuthenticationState =
  createFeatureSelector<AuthenticationState>('auth');

export const selectIsAuthenticated = createSelector(
  selectAuthenticationState,
  (state) => (state.authenticatedUser ? true : false)
);

export const selectAuthenticatedUser = createSelector(
  selectAuthenticationState,
  (state) => state.authenticatedUser
);

export const selectAuthenticatedUserName = createSelector(
  selectAuthenticatedUser,
  (user) => {
    if (user && user.token != '') {
      return user.firstName + ' ' + user.lastName;
    }
    return '';
  }
);

export const selectIsUserOrcaAdmin = createSelector(
  selectAuthenticatedUser,
  (user) => {
    if (user && user.token != '') {
      return user.role === 'orca-admin';
    } else {
      return null;
    }
  }
);

export const selectToken = createSelector(
  selectAuthenticatedUser,
  (user) => user?.token
);

export const selectFleetName = createSelector(
  selectAuthenticatedUser,
  (user) => user?.companyName
);

export const selectSelectedFleetId = createSelector(
  selectAuthenticatedUser,
  (user) => user?.fleetId
);

export const selectSelectedUserId = createSelector(
  selectAuthenticatedUser,
  (user) => user?.id || ''
);
export const selectSelectedUserFullName = createSelector(
  selectAuthenticatedUser,
  (user) => user?.firstName + ' ' + user?.lastName || ''
);

export const selectIsUserShipCaptain = createSelector(
  selectAuthenticatedUser,
  (user) => user?.role === 'ship-captain'
);

export const selectAuthenticatedUserShipId = createSelector(
  selectAuthenticatedUser,
  (user) => user?.shipId
);

export const selectAuthenticatedUserRole = createSelector(
  selectAuthenticatedUser,
  (user) => user?.role as Role
);

export const selectAuthenticatedUserSubFleetShipsId = createSelector(
  selectAuthenticatedUser,
  (user) => user?.subFleetShipsId as number[]
);
