import { createReducer, on } from '@ngrx/store';
import {
  AuthenticationState,
  initialAuthenticationState,
} from './authentication.state';
import { AuthenticationActions } from './types';

export const authenticationStateKey = 'auth';
export const authenticationReducer = createReducer(
  initialAuthenticationState,
  on(
    AuthenticationActions.initAuthUser,
    (state, action): AuthenticationState => ({
      ...state,
      authenticatedUser: action.authUser,
    })
  ),
  on(
    AuthenticationActions.saveAuthUser,
    (state, action): AuthenticationState => ({
      ...state,
      authenticatedUser: action.authUser,
    })
  ),
  on(
    AuthenticationActions.updateUserOnAdminSetClaims,
    (state, action): AuthenticationState => ({
      ...state,
      authenticatedUser: { ...action.user },
    })
  ),
  on(
    AuthenticationActions.updateTokenOnRegularUserSetClaims,
    (state, action): AuthenticationState => ({
      ...state,
      authenticatedUser: { ...state.authenticatedUser!, token: action.token },
    })
  ),
  on(
    AuthenticationActions.logout,
    (state, action): AuthenticationState => ({
      ...state,
      authenticatedUser: null,
    })
  ),
  on(
    AuthenticationActions.logoutFromAllTab,
    (state, action): AuthenticationState => ({
      ...state,
      authenticatedUser: null,
    })
  ),
  on(
    AuthenticationActions.saveRefreshedToken,
    (state, action): AuthenticationState => ({
      ...state,
      authenticatedUser: { ...state.authenticatedUser!, token: action.token },
    })
  )
);
