import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@Component({
  selector: 'app-busy-indicator',
  templateUrl: './busy-indicator.component.html',
  styleUrls: ['./busy-indicator.component.scss'],
  imports: [CommonModule, MatProgressSpinnerModule],
  standalone: true
})
export class BusyIndicatorComponent {
  constructor() {}
}
