import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {
  catchError,
  EMPTY,
  filter,
  from,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { EventsService } from '../services/events.service';
import { MapDataService } from '../services/map-data.service';
import { RtEvent } from '../view-models/event.view.model';
import { MapData } from '../models/map.model';
import { AppState } from '../store/app.state';
import { Store } from '@ngrx/store';
import { AuthenticationSelectors } from '../store/types';
import { IsBusyService } from '../services/is-busy.service';

export const rtEventResolver = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<{ rtEvent: RtEvent; mapData: MapData } | never> => {
  const eventService = inject(EventsService);
  const mapDataService = inject(MapDataService);
  const isBusyService = inject(IsBusyService);
  const store = inject(Store<AppState>);
  const router = inject(Router);

  return store.select(AuthenticationSelectors.selectAuthenticatedUser).pipe(
    filter((value: any) => !!value.token),
    switchMap(() => {
      return getRtEventData();
    })
  );

  function getRtEventData(): Observable<
    { rtEvent: RtEvent; mapData: MapData } | never
  > {
    const eventId = route.params['eventId'];
    const eventPromise = eventService.getRtEventById(eventId);
    isBusyService.add(() => eventPromise);
    return from(eventPromise)
      .pipe(switchMap(async (event) => {
        if(event) {
          const mapPromise = getMapData(event);
          isBusyService.add(() => mapPromise);
          return of({rtEvent: event, mapData: await mapPromise});
        }
        return EMPTY;
      }))
      .pipe(
        switchMap(result => result),
        catchError(() => {
          router.navigate(['/page-not-found']);
          return EMPTY;
        })
      );

    async function getMapData(event: RtEvent): Promise<MapData> {
      const isTheEventOver = event.end !== null;
      return await mapDataService.getRTMapData(event, isTheEventOver);
    }
  }
};
