<div class="header-container">
  <span class="header-banner">
    <img class="icon" src="./assets/icons/orca-icon-white.svg" />
    <h1 class="title">ORCA AI</h1>
  </span>
  <div class="logout-container" (click)="logout()">
    <span class="text-small bold uppercase">{{
      userName$ | async | nameInitials
    }}</span>
  </div>
</div>
