import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { AuthenticationSelectors } from '../store/types';
import { AppState } from '../store/app.state';

@Injectable()
export class AppHttpInterceptorService implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.store
      .select(AuthenticationSelectors.selectAuthenticatedUser)
      .pipe(
        first(),
        switchMap((auth) => {
          req = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${auth?.token}`),
          });
          return this.getNextHandle(req, next);
        })
      );
  }

  private getNextHandle(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
}
