import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserRecoveryGuard implements CanActivate {
  constructor(private router: Router) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const { oobCode: code, mode } = route.queryParams;

    if (code && mode) {
      if (mode === 'resetPassword') {
        this.router.navigate(['auth/new-password'], {
          queryParams: route.queryParams,
        });
        return true;
      }

      this.router.navigateByUrl('/auth');
    }

    return false;
  }
}
