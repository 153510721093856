import dayjs from "dayjs";
import { ShipDto, ShipLastLocation, ShipLastLocationDto } from "../models/ship.models";
import { RtEvent } from "../view-models/event.view.model";
import { EventSeverity, EventType } from "../widgets/mapbox/models/mapbox-event.models";
import { EventMapEntity, PolygonEntity, ShipMapEntity } from "../widgets/mapbox/models/mapbox.models";
import _ from 'lodash';
import { PolygonAreaBorderColors } from "../models/polygon.model";
import { PolygonAreaColors } from "../models/polygon.model";

const POLYGON_COORDIANTE_REGEX = /[0-9 .]+/gm;

export function getMapEntities(shipData: ShipMapEntity, rtEvent: RtEvent, isRtEventOver: boolean): {shipEntity?: ShipMapEntity, eventEntity?: EventMapEntity} {
  if(isRtEventOver) {
    return {
      eventEntity: {
        id: rtEvent.eventId,
        shipId: rtEvent.shipId,
        eventType: rtEvent.type as EventType,
        severity: rtEvent.severity as EventSeverity,
        image: `/assets/map/events/${_.snakeCase(rtEvent.type)}/${_.snakeCase(rtEvent.severity)}.png`,
        lat: rtEvent.lat,
        long: rtEvent.long,
      },
      shipEntity: undefined
    } ;
  } else {
    return {
      eventEntity: undefined,
      shipEntity: shipData
    }
  }
}

export function getPolygonDiameter(polygon: PolygonEntity): number {
  const edges = polygon.coordinates.reduce(({maxX, maxY, minX, minY}, [highX, highY, lowX, lowY]) => {
    return {
        maxX: Math.max(maxX, highX || 0),
        maxY: Math.max(maxY, highY || 0),
        minX: Math.min(minX, lowX || 0),
        minY: Math.min(minY, lowY || 0),
      };
    }, {maxX: 0, maxY: 0, minX: 0, minY: 0});
    return Math.sqrt(Math.pow(edges.maxX - edges.minX, 2) + Math.pow(edges.maxY - edges.minY, 2));
}

export function transformShipDtoToShipMapEntity(shipDto: ShipDto): ShipMapEntity {
  let shipType = _.startCase(shipDto.ship_type)
  .replace('Lng', 'LNG')
  .replace('Lpg', 'LPG')
  .replace('Roro/vehicle', 'RoRo_Vehicle');
  if (!shipType) {
    shipType = 'Container Ship';
  }
  return {
    shipId: shipDto.ship_id,
    shipType: shipType,
    shipName: shipDto.ship_name,
    lastConnection: shipDto.last_connection_datetime ? new Date(shipDto.last_connection_datetime): null,
    sog: shipDto.sog,
    cog: shipDto.cog,
    maxRoll: shipDto.max_roll,
    maxPitch: shipDto.max_pitch,
    safetyScore: shipDto.avg_score ?? 0,
    image: `/assets/map/ships/${_.snakeCase(shipType)}/ship_icon.png`,
    lat: shipDto.latitude ?? 0,
    long: shipDto.longitude ?? 0,
  };
}

export function mapShipLastLocationDtoToShipLastLocation(lastLocation: ShipLastLocationDto): ShipLastLocation {
  const timeString = _.first(lastLocation.time.split('+'));
  return {
    latitude: lastLocation.latitude,
    longitude: lastLocation.longitude,
    sog: Math.floor(lastLocation.sog),
    time: timeString ? dayjs(new Date(timeString)).format('HH:mm') : 'N/A',
  };
}

export function parsePolygonCoordiantes(coordinates: string): number[][] {
  return (coordinates.match(POLYGON_COORDIANTE_REGEX) ?? []).map((point: string) => point.split(' ').map(Number));
}

export function mapPolygonDtoToPolygonEntity(polygonDto: any): PolygonEntity {
  return {
    id: polygonDto.polygon_id,
    textLabel: polygonDto.polygon_area,
    coordinates: parsePolygonCoordiantes(polygonDto.edges),
    labelType: 'red',
    labelSource: polygonDto.polygon_type_name,
    color: PolygonAreaColors[_.snakeCase(polygonDto.labelSource) as keyof typeof PolygonAreaColors],
    borderColor: PolygonAreaBorderColors[_.snakeCase(polygonDto.labelSource) as keyof typeof PolygonAreaBorderColors],
  }
}