import { createAction, props } from '@ngrx/store';
import { Model } from 'dashboard-frontend-library/models';

export const requestResetPassword = createAction(
  '[Reset Password Page] Reset password clicked',
  props<{ resetPasswordReq: Model.ResetPasswordRequest }>()
);
export const passwordResetEmailChecked = createAction(
  '[Reset Password Page] Password Reset Email Checked',
  props<{ passwordReset: Model.PasswordReset }>()
);

export const initAuthUser = createAction(
  '[Auth Effects] Init auth user state',
  props<{ authUser: Model.User | null }>()
);

export const saveAuthUser = createAction(
  '[Auth Effects] Save auth user on change',
  props<{ authUser: Model.User | null }>()
);

export const newPassword = createAction(
  '[New Password Page] Set new password',
  props<{ newPasswordReq: Model.NewPasswordRequest }>()
);

export const updateUserOnAdminSetClaims = createAction(
  '[Auth Effects] Update token after admin sets claims',
  props<{ user: Model.User }>()
);

export const updateTokenOnRegularUserSetClaims = createAction(
  '[Auth Effects] Update token after regular user logs in',
  props<{ token: string }>()
);

export const logoutOnUserClick = createAction(
  '[Side Nav] Logout clicked by user'
);

export const logoutFromAllTab = createAction('[Side Nav] Logout from all tab');

export const logout = createAction('[Side Nav] Logout');

export const saveRefreshedToken = createAction(
  '[Auth Effects] Update refreshed token',
  props<{ token: string }>()
);
