import {
  ApplicationConfig,
  isDevMode,
  importProvidersFrom,
  APP_INITIALIZER,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideServiceWorker } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { provideZoneChangeDetection } from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthEffects } from './store/authentication.effects';
import { InitCoreService } from './services/init.core.service';
import {
  authenticationReducer,
  authenticationStateKey,
} from './store/authentication.reducer';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppHttpInterceptorService } from './services/app-http-interceptor.service';

export const appConfig: ApplicationConfig = {
  providers: [
    [provideAnimations()],
    importProvidersFrom(
      provideFirebaseApp(() => initializeApp(environment.firebase))
    ),
    importProvidersFrom(provideAuth(() => getAuth())),
    importProvidersFrom(provideFirestore(() => getFirestore())),
    importProvidersFrom(
      provideFunctions(() =>
        getFunctions(getApp(), environment.awsFunctionsRegion)
      )
    ),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerImmediately',
    }),
    importProvidersFrom(StoreDevtoolsModule),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (service: InitCoreService) => () => service.init(),
      deps: [InitCoreService],
      multi: true,
    },
    importProvidersFrom(MatSnackBarModule),
    importProvidersFrom(HttpClientModule),
    provideStore({ [authenticationStateKey]: authenticationReducer }),
    provideEffects([AuthEffects]),
  ],
};
