import { Routes } from '@angular/router';
import { isAuthenticatedGuard } from './guards/is-authenticated.guard';
import { FeatureMobileRtEventGuard } from './guards/feature-mobile-rt-event.guard';
import { isUnauthenticatedGuard } from './guards/is-unauthenticated.guard';
import { UserRecoveryGuard } from './guards/user-recovery.guard';
import { rtEventResolver } from './resolvers/rt-event.resolver';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/public/public-routing.module').then(
        (m) => m.PublicRoutingModule
      ),
    canActivate: [isUnauthenticatedGuard],
  },
  {
    path: 'user-management',
    canActivate: [isUnauthenticatedGuard, UserRecoveryGuard],
    loadComponent: () =>
      import(
        '../app/pages/auth/user-management/user-management.component'
      ).then((mod) => mod.UserManagementComponent),
  },

  {
    path: 'rt-event/:eventId',
    canActivate: [FeatureMobileRtEventGuard, isAuthenticatedGuard],
    resolve: { rtEvent: rtEventResolver },
    loadComponent: () =>
      import('./pages/rt-event-page/rt-event-page.component').then(
        (m) => m.RtEventPageComponent
      ),
  },
  {
    path: 'page-not-found',
    loadComponent: () =>
      import('./pages/page-not-found/page-not-found.component').then(
        (m) => m.PageNotFoundComponent
      ),
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
  },
];


export const routeExchangeMap: Record<string, string> = {
  'rt-event/': 'private/events/rt-event-exploration/',
}

