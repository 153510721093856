import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  MatBottomSheet,
  MatBottomSheetModule,
} from '@angular/material/bottom-sheet';
import { LogoutNotificationComponent } from '../logout-notification/logout-notification.component';
import { Observable } from 'rxjs';
import { NameInitialsPipe } from '../../pipes/name-initials.pipe';
import { AuthenticationSelectors } from '../../store/types';
import { AppState } from '../../store/app.state';

@Component({
  selector: 'app-mobile-header',
  standalone: true,
  imports: [CommonModule, MatBottomSheetModule, NameInitialsPipe],
  templateUrl: './mobile-app-header.component.html',
  styleUrls: ['./mobile-app-header.component.scss'],
})
export class MobileAppHeaderComponent implements OnInit {
  userName$!: Observable<string>;

  constructor(
    private bottomSheet: MatBottomSheet,
    private store: Store<AppState>
  ) {}
  ngOnInit(): void {
    this.userName$ = this.store.select(
      AuthenticationSelectors.selectAuthenticatedUserName
    );
  }
  logout() {
    this.bottomSheet.open(LogoutNotificationComponent);
  }
}
