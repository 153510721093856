import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DestroyRef implements OnDestroy {
  private _destroy$ = new Subject<boolean>();

  get destroy$() {
    return this._destroy$.asObservable();
  }

  onDestroy(callback: () => void) {
    this._destroy$.subscribe({ next: callback });
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
