import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CdkDragMove, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';

const MAT_BOTTOM_SHEET_TAG_NAME = 'MAT-BOTTOM-SHEET-CONTAINER';

@Component({
  selector: 'app-draggable-bottom-sheet',
  standalone: true,
  imports: [CommonModule, DragDropModule],
  templateUrl: './draggable-bottom-sheet.component.html',
  styleUrls: ['./draggable-bottom-sheet.component.scss']
})
export class DraggableBottomSheetComponent implements AfterViewInit {
  @Input() draggingLimitVh?: number;
  @Input() draggingBufferVh: number = 0;
  topBottomSheetPixel: number = 0;
  offset = 0;
  bottomSheetElement?: HTMLElement;

  @ViewChild('containerRef')
  contianerRef!: ElementRef<HTMLInputElement>;

  ngAfterViewInit() {
    let bottomSheetElementPointer = this.contianerRef.nativeElement.parentElement;
    while(bottomSheetElementPointer && bottomSheetElementPointer.tagName !== MAT_BOTTOM_SHEET_TAG_NAME) {
      bottomSheetElementPointer = bottomSheetElementPointer.parentElement;
    }
    this.bottomSheetElement = bottomSheetElementPointer ?? undefined;
    this.topBottomSheetPixel = bottomSheetElementPointer?.offsetTop ?? 0;
  }

  public onDrag(params: CdkDragMove<any>): void {
    this.contianerRef.nativeElement.style.transform = '';
    this.offset = params.pointerPosition.y - this.topBottomSheetPixel;
    if(this.bottomSheetElement) {
      if(this.draggingLimitVh) {
        this.offset = getLimitedOffset(this.offset, this.draggingLimitVh);
      }
      this.bottomSheetElement.style.transform = `translateY(${this.offset}px)`;
    }

    function getLimitedOffset(offset: number, limitVh: number): number {
      const pixelsPerScreenPercent = screen.height / 100;
      return Math.max(offset, (pixelsPerScreenPercent * limitVh) * (-1));
    }
  }
}
