export enum GeoRegionMileDiameters {
  'Rio de La Plata'= 409.7567774,
  'Bass Strait'= 451.0064478,
  'Great Australian Bight'= 1864.250365,
  'Tasman Sea'= 2115.206042,
  'Mozambique Channel'= 1406.782836,
  'Savu Sea'= 422.6646963,
  'Timor Sea'= 757.8714576,
  'Bali Sea'= 207.2059056,
  'Coral Sea'= 2221.244886,
  'Flores Sea'= 398.8018674,
  'Solomon Sea'= 1016.361719,
  'Arafura Sea'= 1136.866639,
  'Gulf of Boni'= 208.0828986,
  'Java Sea'= 973.1797238,
  'Ceram Sea'= 545.147741,
  'Bismarck Sea'= 715.836667,
  'Banda Sea'= 900.7198244,
  'Gulf of California'= 735.721395,
  'Bay of Fundy'= 272.4946815,
  'Strait of Gibraltar'= 51.51526409,
  'Alboran Sea'= 271.5774385,
  'Caribbean Sea'= 2011.907981,
  'Gulf of Alaska'= 1662.318304,
  'Bering Sea'= 19104.78541,
  'Chukchi Sea'= 20040.51743,
  'Beaufort Sea'= 2107.421318,
  'Labrador Sea'= 1463.524769,
  'Hudson Strait'= 1132.618251,
  'Davis Strait'= 1657.543561,
  'Baffin Bay'= 2050.855503,
  'Lincoln Sea'= 1995.249029,
  'Bristol Channel'= 162.5510474,
  'Irish Sea and St. George\'s Channel'= 300.3249204,
  'Inner Seas off the West Coast of Scotland'= 343.4161272,
  'Gulf of Aden'= 601.2283619,
  'Gulf of Oman'= 390.8739035,
  'Red Sea'= 1111.711443,
  'Gulf of Aqaba'= 106.9066418,
  'Persian Gulf'= 722.7682342,
  'Ionian Sea'= 547.176798,
  'Tyrrhenian Sea'= 591.4369065,
  'Adriatic Sea'= 600.2571133,
  'Gulf of Suez'= 193.5656668,
  'Mediterranean Sea - Eastern Basin'= 1639.042343,
  'Aegean Sea'= 499.8772577,
  'Sea of Marmara'= 237.8936381,
  'Singapore Strait'= 83.25207106,
  'Celebes Sea'= 681.0437018,
  'Malacca Strait'= 664.9138182,
  'Sulu Sea'= 645.1471053,
  'Gulf of Thailand'= 624.4198765,
  'Eastern China Sea'= 942.7542049,
  'Seto Naikai or Inland Sea'= 306.7442778,
  'Philippine Sea'= 2507.90028,
  'Yellow Sea'= 761.3145673,
  'Gulf of Riga'= 225.8669694,
  'Baltic Sea'= 946.3585504,
  'Gulf of Finland'= 901.3197199,
  'Gulf of Bothnia'= 672.2366697,
  'White Sea'= 934.7025482,
  'East Siberian Sea'= 2574.951178,
  'South Atlantic Ocean'= 6473.037914,
  'Southern Ocean'= 21654.38498,
  'South Pacific Ocean'= 17026.21505,
  'Gulf of Tomini'= 236.818978,
  'Makassar Strait'= 507.8746653,
  'Halmahera Sea'= 322.0700918,
  'Molukka Sea'= 494.08222,
  'Indian Ocean'= 8708.270292,
  'Bay of Bengal'= 1479.982434,
  'South China Sea'= 2100.626234,
  'Arabian Sea'= 2108.73473,
  'North Pacific Ocean'= 12825.06756,
  'The Coastal Waters of Southeast Alaska and British Columbia'= 1169.603254,
  'Gulf of Mexico'= 1352.496117,
  'North Atlantic Ocean'= 6773.900893,
  'Gulf of St. Lawrence'= 1285.800458,
  'Balearic (Iberian Sea)'= 339.921786,
  'Bay of Biscay'= 544.273291,
  'Celtic Sea'= 565.881133,
  'Mediterranean Sea - Western Basin'= 1010.114478,
  'Hudson Bay'= 1470.012605,
  'The Northwestern Passages'= 3695.259021,
  'Arctic Ocean'= 21628.84946,
  'English Channel'= 485.4866394,
  'Barentsz Sea'= 3263.595246,
  'Greenland Sea'= 3380.741707,
  'North Sea'= 1155.805173,
  'Andaman or Burma Sea'= 837.5789623,
  'Black Sea'= 973.6376541,
  'Sea of Azov'= 380.1631079,
  'Japan Sea'= 1516.220249,
  'Sea of Okhotsk'= 2254.444747,
  'Kara Sea'= 3318.057488,
  'Laptev Sea'= 3042.777802,
  'Kattegat'= 303.1601177,
  'Laccadive Sea'= 1063.000448,
  'Skagerrak'= 340.1760828,
  'Norwegian Sea'= 2538.685453,
  'Ligurian Sea'= 167.4906421,
  'Gulf of Guinea'= 1166.32875,
  'Other' = 10800,
};

export const diameterToZoom = (diameter: number) => {
  return -1.598 * Math.log(diameter) + 16.599;
};
