import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, firstValueFrom, map } from 'rxjs';
import { RtEventDto } from '../models/events.model';
import { RtEvent } from '../view-models/event.view.model';
import { IsBusyService } from './is-busy.service';
import { environment } from '../../environments/environment';
import { mapRtEventDtoToRtEvent } from '../utils/events';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(private http: HttpClient, private isBusy: IsBusyService) {}

  getRtEventById(eventId: string): Promise<RtEvent | null> {
    return firstValueFrom(
      this.http.get<RtEventDto>(`${environment.api.rtEvent}/${eventId}`).pipe(
        map((dto): RtEvent => mapRtEventDtoToRtEvent(dto)),
        catchError((err) => {
          throw err;
        })
      )
    );
  }
}
