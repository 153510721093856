import { Injectable } from '@angular/core';
import {
  PolygonEntity,
  ShipMapEntity,
} from '../widgets/mapbox/models/mapbox.models';
import {
  ShipDto,
  ShipLastLocation,
  ShipLastLocationsDto,
} from '../models/ship.models';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, catchError, switchMap, filter, defaultIfEmpty } from 'rxjs/operators';
import { firstValueFrom, forkJoin, Observable, of } from 'rxjs';
import _ from 'lodash';
import dayjs from 'dayjs';
import { RtEvent } from '../view-models/event.view.model';
import {
  diameterToZoom,
  GeoRegionMileDiameters,
} from '../models/geo-locations.model';
import { EasingOptions } from 'mapbox-gl';
import { MapData } from '../models/map.model';
import {
  getPolygonDiameter,
  getMapEntities,
  transformShipDtoToShipMapEntity,
  mapShipLastLocationDtoToShipLastLocation,
  mapPolygonDtoToPolygonEntity,
} from '../utils/transform-map-dtos';

@Injectable({
  providedIn: 'root',
})
export class MapDataService {
  constructor(private httpClient: HttpClient) {}

  getGeoPolygon(polygonId: string): Observable<PolygonEntity | undefined> {
    return this.httpClient.get<any[]>(environment.api.shipsGeoPolygons).pipe(
      map((polygonDtos) =>
        polygonDtos
          .filter((polygonDto) => polygonDto.polygon_id === polygonId)
          .map(mapPolygonDtoToPolygonEntity)
      ),
      switchMap((polygons: PolygonEntity[]) => of(_.first(polygons))),
      catchError((error: HttpErrorResponse) => {
        return of(undefined);
      })
    );
  }

  getShipLastLocations(
    shipId: number,
    startDate: Date,
    endDate: Date
  ): Observable<ShipLastLocation[]> {
    return this.httpClient
      .get<ShipLastLocationsDto>(
        `${environment.api.ships}/${shipId}/last_locations`,
        {
          params: {
            end_date: endDate.toISOString(),
            start_date: startDate.toISOString(),
          },
        }
      )
      .pipe(
        map((shipLastLocations): ShipLastLocation[] =>
          shipLastLocations.locations.map(
            mapShipLastLocationDtoToShipLastLocation
          )
        ),
        catchError((error: HttpErrorResponse) => {
          return of([]);
        })
      );
  }

  getShipData(
    startDate: Date,
    endDate: Date,
    shipId: number
  ): Observable<ShipMapEntity | undefined> {
    return this.httpClient
      .get<ShipDto[]>(`${environment.api.ships}/management/${shipId}`, {
        params: {
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString(),
        },
      })
      .pipe(
        map((shipDtos) => _.first(shipDtos)),
        map(
          (shipDto): ShipMapEntity | undefined =>
            shipDto && transformShipDtoToShipMapEntity(shipDto)
        ),
        catchError((error: HttpErrorResponse) => {
          return of(undefined);
        })
      );
  }

  async getRTMapData(
    rtEvent: RtEvent,
    isRtEventOver: boolean
  ): Promise<MapData> {
    const getGeoPolygonEntities = (
      polygonId: string | undefined
    ): Observable<PolygonEntity[]> =>
      (polygonId
        ? this.getGeoPolygon(polygonId).pipe(
            filter(
              (polygon: PolygonEntity | undefined) => polygon !== undefined
            ),
            map((polygon) => [polygon]),
            defaultIfEmpty([])
          )
        : of([])) as Observable<PolygonEntity[]>;
    const endDate = rtEvent.end ?? new Date();
    const startDate = dayjs(endDate).subtract(1, 'day').toDate();
    const { polygonEntities, locations, shipData } = await firstValueFrom(
      forkJoin({
        polygonEntities: getGeoPolygonEntities(rtEvent.polygonId),
        locations: this.getShipLastLocations(
          rtEvent.shipId,
          startDate,
          endDate
        ),
        shipData: this.getShipData(startDate, endDate, rtEvent.shipId),
      })
    );

    const eventAreaDiameter = _.first(polygonEntities)
      ? getPolygonDiameter(_.first(polygonEntities)!)
      : GeoRegionMileDiameters[
          rtEvent.location as keyof typeof GeoRegionMileDiameters
        ];
    const flyTo: EasingOptions = {
      center: { lat: rtEvent.lat, lng: rtEvent.long },
      zoom: diameterToZoom(eventAreaDiameter),
    };
    if (!shipData) {
      return {
        polygonEntities,
        shipLastLocations: locations,
        shipEntity: undefined,
        eventEntity: undefined,
        flyTo,
      };
    } else {
      const { shipEntity, eventEntity } = getMapEntities(
        shipData,
        rtEvent,
        isRtEventOver
      );
      return {
        polygonEntities,
        shipLastLocations: locations,
        shipEntity,
        eventEntity,
        flyTo,
      };
    }
  }
}
