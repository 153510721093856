import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable, take } from 'rxjs';
import { AppState } from '../store/app.state';
import { AuthenticationSelectors } from '../store/types';

@Injectable({
  providedIn: 'root',
})
export class isUnauthenticatedGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store
      .select(AuthenticationSelectors.selectIsAuthenticated)
      .pipe(
        take(1),
        map((isAuthenticated) => {
          if (isAuthenticated) {
            this.router.navigate(['/page-not-found']);
            return false;
          } else {
            return true;
          }
        })
      );
  }
}
