import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';

import {
  MatBottomSheetModule,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { AppState } from '../../store/app.state';
import { AuthenticationActions } from '../../store/types';
import { DraggableBottomSheetComponent } from '../draggable-bottom-sheet/draggable-bottom-sheet.component';

@Component({
  selector: 'app-logout-notification',
  standalone: true,
  imports: [CommonModule, MatBottomSheetModule, DraggableBottomSheetComponent],
  templateUrl: './logout-notification.component.html',
  styleUrls: ['./logout-notification.component.scss'],
})
export class LogoutNotificationComponent {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<LogoutNotificationComponent>,
    private store: Store<AppState>
  ) {}

  logout() {
    this.store.dispatch(AuthenticationActions.logoutOnUserClick());
    this.bottomSheetRef.dismiss();
  }
}
