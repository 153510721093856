import { Injectable, OnDestroy } from '@angular/core';
import { initialize, LDClient, LDContext } from 'launchdarkly-js-client-sdk';
import { filter, from, map, Observable } from 'rxjs';
import { FeatureFlagState, FeatureKey } from '../models/feature-flag.model';
import { environment } from '../../environments/environment';
import { Model } from 'dashboard-frontend-library/models';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService implements OnDestroy {
  private readonly CLIENT_TIMEOUT = 5;
  client!: LDClient;
  private featureFlags$ = new Map<FeatureKey, boolean>();
  private featureFlagsMap = new Map<FeatureKey, FeatureFlagState>();

  async ngOnDestroy() {
    await this.client.close();
  }

  async init(user: Model.User | null): Promise<void> {
    let context: LDContext;
    if (user) {
      context = {
        kind: 'user',
        key: user.companyName,
      };
    } else {
      context = {
        anonymous: true,
      };
    }
    this.client = initialize(environment.launchdarkly, context);
    await this.client.waitForInitialization(this.CLIENT_TIMEOUT);
    this.initializeFeatureFlags();
  }

  initializeFeatureFlags() {
    Object.entries(this.client.allFlags()).forEach((entry) => {
      this.featureFlags$.set(entry[0] as FeatureKey, entry[1]);
      this.updateFeatureFlagsMap(entry);

      this.client.on(`change:${entry[0]}`, (flagValue) => {
        this.featureFlags$.set(entry[0] as FeatureKey, flagValue);
        this.updateFeatureFlagsMap(entry);
      });
    });
  }

  updateFeatureFlagsMap(entry: [string, any]) {
    let key = entry[0] as FeatureKey;
    let value = entry[1];

    // TODO: Deprecate old states
    if (typeof value === 'boolean') {
      let state = value ? FeatureFlagState.Enabled : FeatureFlagState.Disabled;
      this.featureFlagsMap.set(key, state);
      return;
    }

    if (
      typeof value !== 'number' ||
      !Object.values(FeatureFlagState).includes(value)
    ) {
      console.warn(
        `[FEATURE_FLAG_SERVICE]:[UPDATE_FEATURE_FLAGS_MAP]: Wrong type of flag's value for entry key - ${key}`
      );
      return;
    }

    let state = value as FeatureFlagState;
    this.featureFlagsMap.set(key, state);
  }

  getFeatureFlag$(flagKey: FeatureKey): Observable<boolean> {
    return from(this.client.waitForInitialization(this.CLIENT_TIMEOUT)).pipe(
      filter(() => this.featureFlags$.has(flagKey)),
      map(() => this.featureFlags$.get(flagKey) as boolean)
    );
  }

  getFeatureFlagState$(flagKey: FeatureKey): Observable<FeatureFlagState> {
    return from(this.client.waitForInitialization(this.CLIENT_TIMEOUT)).pipe(
      filter(() => this.featureFlagsMap.has(flagKey)),
      map(() => this.featureFlagsMap.get(flagKey) ?? FeatureFlagState.Disabled)
    );
  }
}
