import { EventType } from '../view-models/event.view.model';
import { EventSeverityKeys } from '../view-models/general.event.view.model';

export enum RTEventImage {
  'Compliance' = './assets/icons/compliance_event.svg',
  'No Go Zone' = './assets/icons/no_go_zone_event.svg',
  'High Pitch/Roll' = './assets/icons/high_pitch_roll_event.svg',
  'Default' = './assets/icons/rt_event.svg',
}

export interface RtEventDto {
  readonly event_id: string;
  readonly fleet_id: number;
  readonly ship_name: string;
  readonly ship_id: number;
  readonly event_type: AlertNames;
  readonly severity_level: EventSeverityKeys;
  readonly timestamp_start: string;
  readonly timestamp_end: string | null;
  readonly lat_start: number;
  readonly long_start: number;
  readonly sog_start: number;
  readonly cog_start: number;
  readonly pitch_start: number;
  readonly roll_start: number;
  readonly polygon_uuid: string;
  readonly area_of_interest: string;
  readonly textual_location: string;
  readonly screenshots: RtEventScreenshotDto[];
  readonly imu_samples: RtEventImuSampleDto[];
  readonly aoi_compliance_limitations: RTEventLimitationsDto;
}

export interface RtEventScreenshotDto {
  cdn_url: string;
  captured_at: string;
}

export interface RtEventImuSampleDto {
  readonly roll: number;
  readonly pitch: number;
  readonly sog: number;
  readonly sampled_at: string;
}

export interface RTEventLimitationsDto {
  readonly min_allowed_sog: number;
  readonly max_allowed_sog: number;
  readonly sog_not_in_range: {
    min_value: number;
    max_value: number;
  };
  readonly min_allowed_cog: number;
  readonly max_allowed_cog: number;
  readonly cog_not_in_range: {
    min_value: number;
    max_value: number;
  };
}

export interface RangeDto {
  readonly min_value: number;
  readonly max_value: number;
}

export type AlertNames = 'Compliance' | 'No Go Zone';

export type RtEventType =
  | 'Dangerous motion'
  | 'No Go Zone'
  | 'Compliance'
  | 'Sog Compliance'
  | 'Cog Compliance';

export type RtEventTypeToRtEventNameMap = { [key in RtEventType]: EventType };

export const rtEventTypeToRtEventName: RtEventTypeToRtEventNameMap = {
  'Dangerous motion': 'High Pitch/Roll',
  'No Go Zone': 'No Go Zone',
  Compliance: 'Compliance',
  'Sog Compliance': 'Compliance',
  'Cog Compliance': 'Compliance',
};
