/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { FeatureFlagService } from './feature-toggle.service';
import { IsBusyService } from './is-busy.service';
@Injectable({
  providedIn: 'root',
})
export class InitCoreService {
  constructor(
    private auth: AuthService,
    private isBusy: IsBusyService,
    private featureFlag: FeatureFlagService
  ) {}

  async init() {
    this.isBusy.init();
    const user = await this.auth.init();
    await this.featureFlag.init(user);
  }
  
}
