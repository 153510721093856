import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { FeatureFlagService } from '../services/feature-toggle.service';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FeatureMobileRtEventGuard {
  constructor(
    private featureFlag: FeatureFlagService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.featureFlag.getFeatureFlag$('mobile').pipe(
      map((hasPermissionForMobile) => {
        if (!hasPermissionForMobile) {
          this.router.navigateByUrl('/page-not-found');
        }
        return hasPermissionForMobile;
      }),
      catchError(() => {
        this.router.navigateByUrl('/page-not-found');
        return of(false);
      })
    );
  }
}
