import { Component, Inject, signal } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { DeviceRouterService } from 'dashboard-frontend-library';
import { NotificationsService } from './services/notifications.service';
import { IsBusyService } from './services/is-busy.service';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { environment } from '../environments/environment';
import { routeExchangeMap } from './app.routes';
import { MobileAppHeaderComponent } from './components/mobile-app-header/mobile-app-header.component';
import { AuthService } from './services/auth.service';
import { takeUntil, tap } from 'rxjs';
import { DestroyRef } from './services/destroy-ref.service';
import { scaleText } from './utils/viewport-text-scaler.utils';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    MatSnackBarModule,
    RouterModule,
    MatProgressSpinnerModule,
    MobileAppHeaderComponent,
  ],
  providers: [MatSnackBarModule, NotificationsService, IsBusyService],
  templateUrl: './app.component.html',
})
export class AppComponent {
  isAuthenticated = signal(false);

  constructor(
    @Inject(DeviceRouterService) private deviceRouterService: DeviceRouterService,
    private authService: AuthService,
    private destroyRef: DestroyRef
  ) {
    this.authService.currentAuthenticatedUser$.pipe(
      takeUntil(this.destroyRef.destroy$),
      tap((user) => this.isAuthenticated.set(!!user))
    ).subscribe();

    this.deviceRouterService.navigateToDeviceApp(environment.mobileAppOrigin, environment.desktopAppOrigin, routeExchangeMap);
    scaleText(window);
  }
}
