import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthenticationSelectors } from '../store/types';
import { AppState } from '../store/app.state';

@Injectable({
  providedIn: 'root',
})
export class isAuthenticatedGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const authenticated = await firstValueFrom(
      this.store.select(AuthenticationSelectors.selectIsAuthenticated)
    );

    if (!authenticated) {
      this.router.navigate(['/auth'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
    return true;
  }
}
