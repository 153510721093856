export type FeatureKey =
  | 'LiveView'
  | 'FeatureLock'
  | 'mobile'
  | 'ff-livepage-v2';

export interface FeatureFlag {
  readonly featureKey: FeatureKey;
  readonly value: boolean;
}

export enum FeatureFlagState {
  Enabled = 1,
  Disabled = 2,
  Expired = 3,
}
