import * as _ from 'lodash';
import {
  RTEventLimitationsDto,
  RangeDto,
  RtEventDto,
  RtEventImuSampleDto,
  RtEventScreenshotDto,
  rtEventTypeToRtEventName,
} from '../models/events.model';
import {
  Limitation,
  RTEventLimitations,
  RTEventScreenshot,
  RtEvent,
  RtEventImuSample,
} from '../view-models/event.view.model';
import {
  ShipStreamDto,
  ShipCamera,
  ShipCameraDto,
  ShipStream,
} from '../models/live-stream.model';
import { Range } from '../view-models/range.view.model';

export function mapRtEventDtoToRtEvent(eventDto: RtEventDto): RtEvent {
  return {
    shipId: eventDto.ship_id,
    fleetId: eventDto.fleet_id,
    eventId: eventDto.event_id,
    type: rtEventTypeToRtEventName[eventDto.event_type] ?? eventDto.event_type,
    keplerUrl: null,
    location: eventDto.textual_location,
    timestamp: new Date(eventDto.timestamp_start),
    timestampEnd: eventDto.timestamp_end ? new Date(eventDto.timestamp_end) : undefined,
    videoUrls: [],
    lat: eventDto.lat_start,
    long: eventDto.long_start,
    severity: eventDto.severity_level,
    shipName: eventDto.ship_name ?? '',
    start: new Date(eventDto.timestamp_start),
    sog: eventDto.sog_start,
    cog: eventDto.cog_start,
    end: eventDto.timestamp_end ? new Date(eventDto.timestamp_end) : null,
    pitch: eventDto.pitch_start,
    roll: eventDto.roll_start,
    polygonId: eventDto.polygon_uuid,
    areaOfInterest: eventDto.area_of_interest,
    screenshots: eventDto.screenshots.map((screenshot) =>
      mapRtEventScreenshotDtoToRtEventScreenshot(screenshot)
    ),
    imuSamples: eventDto.imu_samples.map((sample) =>
      mapRtEventImuSampleDtoToRtEventImuSample(sample)
    ),
    aoiComplianceLimitations: mapRtEventLimitationsDtoToRtEventLimitations(
      eventDto.aoi_compliance_limitations
    ),
  };
}

export function mapRtEventScreenshotDtoToRtEventScreenshot(
  screenshot: RtEventScreenshotDto
): RTEventScreenshot {
  return {
    url: screenshot.cdn_url,
    timestamp: screenshot.captured_at,
  };
}

export function mapRtEventImuSampleDtoToRtEventImuSample(
  imuSample: RtEventImuSampleDto
): RtEventImuSample {
  return {
    sog: imuSample.sog,
    roll: imuSample.roll,
    pitch: imuSample.pitch,
    sampledAt: new Date(imuSample.sampled_at.split('+')[0]),
  };
}

function getNotInRange(notInRange?: RangeDto) {
  return notInRange
    ? {
        notInRange: {
          min: notInRange.min_value,
          max: notInRange.max_value,
        } as Range,
      }
    : undefined;
}

function mapLimitation(
  minAllowed?: number,
  maxAllowed?: number,
  notInRange?: RangeDto
): Limitation | null {
  if (minAllowed || maxAllowed || notInRange) {
    return {
      minAllowed,
      maxAllowed,
      ...getNotInRange(notInRange),
    };
  } else return null;
}

export function mapRtEventLimitationsDtoToRtEventLimitations(
  limitations?: RTEventLimitationsDto
): RTEventLimitations | null {
  if (!limitations) {
    return null;
  }

  const sog = mapLimitation(
    limitations.min_allowed_sog,
    limitations.max_allowed_sog,
    limitations.sog_not_in_range
  );

  const cog = mapLimitation(
    limitations.min_allowed_cog,
    limitations.max_allowed_cog,
    limitations.cog_not_in_range
  );

  return {
    sog,
    cog,
  };
}

export function getLimitationText(
  limitation: Limitation,
  unit: string
): string {
  const rtEventLimitationsDescriptions: string[] = [];

  if (limitation.maxAllowed) {
    rtEventLimitationsDescriptions.push(
      `Max Allowed: ${limitation.maxAllowed} ${unit}`
    );
  }
  if (limitation.minAllowed) {
    rtEventLimitationsDescriptions.push(
      `Min Allowed: ${limitation.minAllowed} ${unit} `
    );
  }
  if (limitation.notInRange) {
    rtEventLimitationsDescriptions.push(
      `Not Within: ${limitation.notInRange.min}-${limitation.notInRange.max} ${unit} `
    );
  }
  return rtEventLimitationsDescriptions.join(', ');
}

export function isViolated(value: number, limitation: Limitation): boolean {
  const violatedValueNotInRange = limitation.notInRange
    ? value > limitation.notInRange.min && value < limitation.notInRange.max
    : false;
  const violatedValueInMaxAllowed = limitation.maxAllowed
    ? value > limitation.maxAllowed
    : false;
  const violatedValueInMinAllowed = limitation.minAllowed
    ? value < limitation.minAllowed
    : false;
  const isViolatedLimitation =
    violatedValueNotInRange ||
    violatedValueInMinAllowed ||
    violatedValueInMaxAllowed;
  return isViolatedLimitation;
}

export function mapStreamDto(stream: ShipStreamDto): ShipStream {
  return {
    isStreamMaster: stream.isMaster,
    streamUrl: stream.streamHLS,
    sessionId: stream.session_id,
  };
}

export function mapShipCameraDto(camera: ShipCameraDto): ShipCamera {
  return {
    cameraId: camera.camera_id,
    isLive: camera.streaming_state === 'live',
    streamingStartedAt: camera.streaming_started_at,
  };
}
